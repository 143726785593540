import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import {
  usePnoBreakdownPerformanceTestLazyQuery,
} from '../../../generated/graphql';
import ErrorMessage from '../../common/ErrorMessage';

interface Props {
  structureWeek: number
  plantCode: number
  pnos: { name: string }[]
}

export function BreakdownPerformanceTestPno(props: Props) {
  const [took, setTook] = useState<number | null>(null);
  const [call, {
    data, error, loading,
  }] = usePnoBreakdownPerformanceTestLazyQuery({
    variables: {
      structureWeek: props.structureWeek,
      plant: { plantCode: props.plantCode },
      pnos: props.pnos,
    },
    nextFetchPolicy: 'no-cache',
    fetchPolicy: 'no-cache',
  });

  return (
    <>
      <Paper sx={{ p: 2, mt: 2 }}>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={async () => {
            setTook(null);
            const start = performance.now();
            await call();
            setTook(performance.now() - start);
          }}
        >Breakdown of {props.pnos.length} pnos using GraphQL</LoadingButton>

        <ErrorMessage error={error} />

        {(data && took) && (
          <Box mt={2}>
            Took: {Math.round(took * 10) / 10} s
            to get {data.storedCpvSets.length} breakdown results
          </Box>
        )}

        {data && (
          <Box sx={{
            whiteSpace: 'pre',
            overflowX: 'auto',
            fontSize: 10,
            my: 2,
          }}>
            {data.storedCpvSets.map((row) => (
              <Stack direction="row" spacing={2} key={row.pno}>
                <Box>{row.pno}</Box>
                <Box>{row.pmlIdsWithTypeCode.join(', ')}</Box>
              </Stack>
            ))}
          </Box>
        )}
      </Paper>

    </>
  );
}
