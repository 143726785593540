import React from 'react';
import { Chip, Tooltip } from '@mui/material';
import { PlantInfoFragment } from '../../../../../generated/graphql';

export function PlantChip(props: {
  plant: PlantInfoFragment;
}) {
  const { plant } = props;

  return (
    <Tooltip
      title={`${plant.name} | ${plant.abbreviation}`}
    >
      <Chip
        label={plant.plantCode}
        variant="outlined"
      />
    </Tooltip>
  );
}
