import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { PartDialog_ConsumptionRecordFragment } from '../../../../../../generated/graphql';
import { FieldBoolean, FieldInteger, FieldString } from '../../common/FieldValues';
import { FieldCoi } from '../../common/FieldCoi';

interface Props {
  consumptionRecords: PartDialog_ConsumptionRecordFragment[];
}

export function ConsumptionRecordsTable(props: Props) {
  const { consumptionRecords } = props;
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="right">POST</TableCell>
            <TableCell>Add</TableCell>
            <TableCell>Del</TableCell>
            <TableCell>Type Code</TableCell>
            <TableCell>Plant</TableCell>
            <TableCell>DU-marking</TableCell>
            <TableCell>Is revoked</TableCell>
          </TableRow>
        </TableHead>
          <TableBody>
            {consumptionRecords?.map((c) => (
              <TableRow
                key={c.id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  ...(c.isRevoked ? {
                    opacity: 0.5,
                    textDecoration: 'line-through',
                  } : {}),
                }}
              >
                <TableCell>
                  <FieldInteger alignRight value={c.POST}/>
                </TableCell>
                <TableCell>
                  <FieldCoi
                    coiNumber={c.changeOrderIssueAdd.coiNumber}
                    coiNumberTooltip="consumptionRecord.changeOrderIssueAdd.coiNumber"
                    week={c.effectiveInWeek}
                    weekTooltip="consumptionRecord.effectiveInWeek"
                  />
                </TableCell>
                <TableCell>
                  <FieldCoi
                    coiNumber={c.changeOrderIssueDel?.coiNumber}
                    coiNumberTooltip="consumptionRecord.changeOrderIssueDel.coiNumber"
                    week={c.effectiveOutWeek}
                    weekTooltip="consumptionRecord.effectiveOutWeek"
                  />
                </TableCell>
                <TableCell>
                  <FieldString value={c.mbom?.ebom?.productContext?.typeCode} />
                </TableCell>
                <TableCell>
                  <FieldString value={c.plant?.name} />
                  <FieldInteger value={c.plant?.plantCode} />
                </TableCell>
                <TableCell>
                  <FieldString value={c.duMarking} />
                </TableCell>
                <TableCell>
                  <FieldBoolean value={c.isRevoked} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
      </Table>
    </TableContainer >
  );
}
