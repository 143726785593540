import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';

interface Props {
  type: string,
  primaryText?: string | null
  secondaryText?: string | null,
  onCloseClick: () => void,
}

/**
 * Dialog title with close icon.
 */
export function DialogHeader(props: Props) {
  return (
    <DialogTitle>
      <Stack alignItems="center" direction="row" spacing={1.5}>
        <div>
          {props.type} {props.primaryText}
        </div>
        <Box sx={{ color: 'secondary.main', flexGrow: 1 }}> {props.secondaryText}</Box>
        <Button color="error" onClick={props.onCloseClick}>Close</Button>
      </Stack>
    </DialogTitle>
  );
}
