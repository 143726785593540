import React from 'react';
import {
  Box,
  List,
  Paper,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import {
  BopRoutingDialog_BopCompoundOperationFragment,
  BopRoutingDialogQuery,
  MfgPartUsageInfoFragment,
} from '../../../../../generated/graphql';
import { FieldGridFlex, FieldListValue } from '../common/FieldListValue';
import { ColoredPaper } from '../../../../common/style-elements/ColoredPaper';
import { colors } from '../../../../../services/mui-theme';
import { TypeName } from '../../../../common/style-elements/TypeNameHeader';
import {
  EffectivitiesPanel,
  MfgPartUsageInfo,
  MfgAssemblyInfo,
} from '../common/mfg/MfgAssemblyPlaceholderInfo';

type BopRouting = BopRoutingDialogQuery['bopRoutings'][0];

interface Props {
  bopRouting?: BopRouting | null
}
export function BopRoutingInfoPanel(props: Props) {
  const { bopRouting } = props;

  if (!bopRouting) {
    return null;
  }

  return (
    <>
      <Box p={2} mb={4}>
        <ColoredPaper borderColor={colors.brand.blue4} backgroundColor={'#f4f7fc'}>
          <FieldGridFlex>
            <TypeName color={colors.brand.blue3}>{bopRouting?.__typename}</TypeName>
            <FieldListValue name="name" value={bopRouting?.name}/>
            <FieldListValue name="revision" value={bopRouting?.revision}/>
            <FieldListValue name="catalogueId" value={bopRouting?.catalogueId}/>
          </FieldGridFlex>
          <EffectivitiesPanel effectivities={bopRouting.effectivities}/>

          {bopRouting.subRoutings.map((subRouting, index) => (
            <ColoredPaper borderColor={colors.brand.gray4} backgroundColor={'#eaeff6'} key={index}>
              <FieldGridFlex>
                <TypeName color={colors.brand.gray3}>{subRouting.__typename}</TypeName>
                <FieldListValue name="name" value={subRouting?.name}/>
                <FieldListValue name="revision" value={subRouting?.revision}/>
                <FieldListValue name="catalogueId" value={subRouting?.catalogueId}/>
              </FieldGridFlex>
              <EffectivitiesPanel effectivities={subRouting.effectivities}/>
              {subRouting.compoundOperations.map((co, index2) => (
                <CompoundOperationInfo key={index2} compoundOperation={co}/>
              ))}
            </ColoredPaper>
          ))}

          {bopRouting.targets.map((target, index) => (
            <ColoredPaper borderColor={colors.brand.gray4} backgroundColor={'#fffefc'} key={index}>
              <FieldGridFlex>
                <TypeName color={colors.brand.gray3}>{target.__typename}</TypeName>
                <FieldListValue name="variantFormula" value={target.variantFormula}/>
              </FieldGridFlex>
              <EffectivitiesPanel effectivities={target.effectivities}/>
              <MfgAssemblyInfo mfgAssembly={target.assembly}/>
            </ColoredPaper>
          ))}

          {bopRouting.compoundOperations.map((co, index) => (
            <CompoundOperationInfo key={index} compoundOperation={co}/>
          ))}
        </ColoredPaper>
        <Typography variant="h3" mb={1} mx={2}>Mfg Change Order</Typography>
        <Paper variant="outlined">
          <List disablePadding>
            <FieldListValue name="name" value={bopRouting.name}/>
            <FieldListValue name="revision" value={bopRouting.revision}/>
            <FieldListValue name="catalogueId" value={bopRouting.catalogueId}/>
          </List>
        </Paper>
      </Box>
    </>
  );
}

function CompoundOperationInfo(props: {
  compoundOperation: BopRoutingDialog_BopCompoundOperationFragment,
}) {
  const { compoundOperation: co } = props;

  return (
    <ColoredPaper borderColor={colors.brand.orange4} backgroundColor={'#fffefc'}>
      <FieldGridFlex>
        <TypeName color={colors.brand.orange3}>{co.__typename}</TypeName>
        <FieldListValue name="name" value={co.name}/>
        <FieldListValue name="revision" value={co.revision}/>
        <FieldListValue name="compoundOpType" value={co.compoundOpType}/>
        <FieldListValue name="workStation" value={co.workStation}/>
        <FieldListValue name="workStation" value={co.workStation}/>
      </FieldGridFlex>
      <EffectivitiesPanel effectivities={co.effectivities}/>
      {co.operations.map((op, index2) => (
        <ColoredPaper backgroundColor={'#fcf1ef'} borderColor={colors.brand.red4} key={index2}>
          <FieldGridFlex>
            <TypeName color={colors.brand.red3}>{op.__typename}</TypeName>
            <FieldListValue name="name" value={op.name}/>
            <FieldListValue name="revision" value={op.revision}/>
            <FieldListValue name="activityType" value={op.activityType}/>
            <FieldListValue name="activityId" value={op.activityId}/>
            <FieldListValue name="equipment" value={op.equipment}/>
            <FieldListValue name="variantFormula" value={op.variantFormula}/>
          </FieldGridFlex>
          <ColoredPaper borderColor={colors.brand.gray5} backgroundColor={'#fffdfd'}>
            <FieldGridFlex>
              <TypeName color={colors.brand.gray3}>Parameters</TypeName>
              {op.parameters.map((param, index3) => (
                <FieldListValue key={index3} name={param.name} value={param.value}/>
              ))}
            </FieldGridFlex>
          </ColoredPaper>
          <EffectivitiesPanel effectivities={op.effectivities}/>
          {op.bopPartUsages.map((pu, index3) => (
            <ColoredPaper borderColor={colors.brand.blue4} backgroundColor={'#f4f7fc'} key={index3}>
              <FieldGridFlex>
                <TypeName color={colors.brand.blue3}>{pu.__typename}</TypeName>
                <FieldListValue name="quantity" value={pu.quantity}/>
                <FieldListValue name="psa" value={pu.psa}/>
                <FieldListValue name="partPosition" value={pu.partPosition}/>
                <FieldListValue name="partMagazine" value={pu.partMagazine}/>
                <FieldListValue name="partDelayTime" value={pu.partDelayTime}/>
                <FieldListValue name="occurrenceType" value={pu.occurrenceType}/>
                <FieldListValue name="variantFormula" value={pu.variantFormula}/>
              </FieldGridFlex>
              <EffectivitiesPanel effectivities={pu.effectivities}/>
              {pu.mfgPartUsage ? (
                <MfgPartUsageInfo partUsage={pu.mfgPartUsage as MfgPartUsageInfoFragment}/>
              ) : (
                <Alert severity="error">No MfgPartUsage</Alert>
              )}
            </ColoredPaper>
          ))}
        </ColoredPaper>
      ))}
    </ColoredPaper>
  );
}
