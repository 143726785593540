import React, { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Tab from '@mui/material/Tab';
import { useWindowSize } from '@react-hook/window-size';
import { DeliveryModuleWhere, LmodDialogDocument, useLmodDialogQuery } from '../../../../../generated/graphql';
import ErrorMessage from '../../../../common/ErrorMessage';
import { GraphViewResult } from '../../../../common/GraphqlCodeBlock/GraphView/GraphView';
import { formatQueryResultForGraph } from '../../../../common/GraphqlCodeBlock/GraphView/graphview.util';
import { PrettyJson } from '../../../../common/data/PrettyJson';
import { PrettyQuery } from '../../../../common/data/PrettyQuery';

import { DialogLoader } from '../../../../common/dialog/DialogLoader';
import { LmodDialogInfoPanel } from './LmodDialog/LmodDialogInfoPanel';
import { SlideUpTransition } from '../parts/PartDialog/SlideUpTransition';
import { DialogHeader } from '../../../../common/dialog/DialogHeader';

interface Props {
  where: DeliveryModuleWhere,
  close: () => void
}

export function LmodDialog(props: Props) {
  const [open, setOpen] = useState(true);
  const [tab, setTab] = React.useState('1');
  const [width, height] = useWindowSize();

  const { data, loading, error } = useLmodDialogQuery({
    variables: {
      where: props.where,
    },
  });

  const lmod = data?.deliveryModules[0];

  const handleClose = () => {
    setOpen(false);
    setTimeout(props.close, 1000);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth="xl"
      TransitionComponent={SlideUpTransition}
      sx={{
        transform: 'translateZ(0)',
      }}
    >
      <DialogHeader
        type="LMOD"
        primaryText={lmod?.partNumber}
        secondaryText={lmod?.name}
        onCloseClick={handleClose}
      />
      {loading && <DialogLoader/>}
      <ErrorMessage error={error}/>

      <TabContext value={tab} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e, v) => setTab(v)}>
            <Tab label="Info" value="1" />
            <Tab label="Graph" value="2" />
            <Tab label="Json" value="3" />
            <Tab label="Example Query" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 0 }}>
          <LmodDialogInfoPanel lmod={lmod}/>
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          {data && <GraphViewResult
            graphData={formatQueryResultForGraph(data)}
            width={Math.min(width - 64, 1536)}
            height={height - 32 - 156}
            displayTypename={true}
            dagMode
          />}
        </TabPanel>
        <TabPanel value="3">
          {data && <PrettyJson data={data} />}
        </TabPanel>
        <TabPanel value="4">
          <PrettyQuery query={LmodDialogDocument.loc?.source.body.trim() || ''}/>
        </TabPanel>
      </TabContext>
    </Dialog>
  );
}
