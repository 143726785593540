import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ErrorMessage from '../../common/ErrorMessage';
import { useLabelBreakdownPerformanceTestLazyQuery, LabelBreakdownResultFragment } from '../../../generated/graphql';

interface InputRow {
  key: string;
  features: string[];
  plantCode: number;
  structureWeek: number;
  typeCode: string;
}

interface Props {
  input: InputRow;
}
export function LabelBreakdownPerformanceTest(props: Props) {
  const [call, {
    data,
    error,
    loading,
  }] = useLabelBreakdownPerformanceTestLazyQuery({
    variables: {
      inputs: props.input,
    },
    nextFetchPolicy: 'no-cache',
  });

  return (
    <>
      <Paper sx={{ p: 2, mt: 2 }}>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => call()}
        >LabelBreakdown for the first cpv set using GraphQL</LoadingButton>

        <ErrorMessage error={error}/>

        {data && (
          <Box mt={2}>
            Took: {data.partLabelBreakDown.took} ms
          </Box>
        )}
      </Paper>

      {data && (
        <LabelBreakdownResultTable rows={data.partLabelBreakDown.parts}/>
      )}

    </>
  );
}

function LabelBreakdownResultTable(props: { rows: LabelBreakdownResultFragment[] }) {
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            {props.rows.map((row, i) => (
              <TableRow key={i}>
                <TableCell>{row.partNumber}</TableCell>
                <TableCell>{row.labelDefinition}</TableCell>
                <TableCell>{row.labelPosition}</TableCell>
                <TableCell>{row.labelTextParameter}</TableCell>
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </TableContainer>
    </Paper>
  );
}
