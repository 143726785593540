/* eslint-disable max-len */
import React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import {
  PartDialog_PartUsageFragment,
} from '../../../../../../generated/graphql';
import { FieldMiniTable } from '../../common/FieldMiniTable';
import { PartAddressInfoBox } from '../../bom/common/PartAddressInfoBox';
import { FieldBoolean, FieldInteger } from '../../common/FieldValues';
import { FieldCoi } from '../../common/FieldCoi';

interface Props {
  partUsages?: PartDialog_PartUsageFragment[];
}

export function PartUsagesTable(props: Props) {
  const { partUsages } = props;

  if (!partUsages) {
    return null;
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="right">POST</TableCell>
            <TableCell>Add</TableCell>
            <TableCell>Del</TableCell>
            <TableCell>Bom Line</TableCell>
            <TableCell>Usage Rules</TableCell>
            <TableCell>Is revoked</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {partUsages.map((pu, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child > td, &:last-child > th': { border: 0 } }}
            >
              <TableCell>
                <FieldInteger alignRight value={pu.POST}/>
              </TableCell>
              <TableCell>
                <FieldCoi
                  coiNumber={pu.coiNumberAdd}
                  coiNumberTooltip="partUsage.coiNumberAdd"
                  week={pu.effectiveInWeek}
                  weekTooltip="partUsage.effectiveInWeek"
                />
              </TableCell>
              <TableCell>
                <FieldCoi
                  coiNumber={pu.coiNumberDel}
                  coiNumberTooltip="partUsage.coiNumberDel"
                  week={pu.effectiveOutWeek}
                  weekTooltip="partUsage.effectiveOutWeek"
                />
              </TableCell>
              <TableCell>
                <PartAddressInfoBox partAddress={pu.partAddress}/>
              </TableCell>
              <TableCell>
                {pu.usageRules.map((ur) => (
                  <Box key={ur.id} sx={{
                    '&:not(:first-child)': { mt: 1, pt: 1, borderTop: '1px solid #ddd' },
                  }}>
                    <FieldMiniTable>
                      <TableRow>
                        <TableCell variant="head">pmlId:</TableCell>
                        <TableCell>{ur.pmlId}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">typeCode:</TableCell>
                        <TableCell>{ur.typeCode}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="head">varCodes:</TableCell>
                        <TableCell>{ur.varCodes}</TableCell>
                      </TableRow>
                    </FieldMiniTable>
                  </Box>
                ))}
              </TableCell>
              <TableCell>
                <FieldBoolean value={pu.isRevoked} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
