import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { fetchBackendText } from '../../../services/restClient';

interface InputRow {
  key: string;
  features: string[];
  plant: number | string;
  structureWeek: number;
  typeCode: string;
}

interface Props {
  inputs: InputRow[];
}

export function BreakdownPerformanceTestRest(props: Props) {
  const [result, setResult] = useState<string | null>(null);

  // is loading
  const [loading, setLoading] = useState<boolean>(false);

  // Error
  const [error, setError] = useState<string | null>(null);

  const [fileSize, setFileSize] = useState<number | null>(null);

  const call = async () => {
    setLoading(true);
    fetchBackendText('variant-breakdown2', 'POST', formatInput(props.inputs), (bytes) => {
      setFileSize(bytes);
    })
      .then((response) => {
        setError(null);
        setResult(response);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => setLoading(false));

    // Save result into a state
  };

  return (
    <>
      <Paper sx={{ p: 2, mt: 2 }}>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => call()}
        >Breakdown of {props.inputs.length} collections feature sets using Rest</LoadingButton>
      </Paper>

      {fileSize && (
        <Alert severity="info">Response size: {Math.round(fileSize / 1000)} kB</Alert>
      )}

      <Paper>
        {error && (
          <Alert severity="error">{error}</Alert>
        )}
        {result && (
          <Box sx={{
            overflow: 'auto',
          }}>
            <Box p={2} sx={{
              fontSize: '9px',
              whiteSpace: 'pre',
            }}>{result}</Box>
          </Box>
        )}
      </Paper>

    </>
  );
}

function formatInput(inputs: InputRow[]) {
  return inputs.map((input) => {
    return `${input.key},${input.plant},${input.typeCode},${input.structureWeek},${input.features.join(',')}`;
  }).join('\n');
}
