import { environmentSubject } from '../hooks/useSelectedEnvironment';
import { currentAuth } from '../hooks/useAuth';

export async function fetchBackendJson<T>(
  path: string,
  method = 'GET',
  onProgress?: (bytes: number) => void,
): Promise<T> {
  const url = new URL(path, environmentSubject.value.graphQLEndpoint).toString();
  const auth = currentAuth();

  const response = await fetch(url, {
    method,
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/json',
      authorization: auth ? `Bearer ${auth.idToken}` : '',
    },
  });

  if (!response.ok) {
    // If the response is not 2xx, throw an error
    throw new Error(`An error occurred: ${response.statusText}`);
  }

  if (onProgress) {
    let length = 0;
    // call onProgress in 2 ms intervals and updating length based on the result
    // from the response body
    const reader = response.clone().body?.getReader();
    if (reader) {
      const interval = setInterval(async () => {
        const { value, done } = await reader.read();
        if (value) {
          length += value.byteLength;
          onProgress(length);
        }
        if (done) {
          clearInterval(interval);
        }
      }, 2);
    }
  }

  return response.json(); // Assuming the server responds with JSON
}

export async function fetchBackendText(
  path: string,
  method = 'GET',
  body?: string, // Add this line to accept a request body as string
  onProgress?: (bytes: number) => void,
): Promise<string> {
  const url = new URL(path, environmentSubject.value.graphQLEndpoint).toString();
  const auth = currentAuth();

  const fetchOptions: RequestInit = {
    method,
    headers: {
      Accept: '*/*',
      'Content-Type': 'text/plain',
      authorization: auth ? `Bearer ${auth.idToken}` : '',
    },
  };

  // Add the body to the request if the method is POST and the body is not undefined
  if (method === 'POST' && body !== undefined) {
    fetchOptions.body = body;
  }

  const response = await fetch(url, fetchOptions);

  if (!response.ok) {
    // If the response is not 2xx, throw an error
    throw new Error(`An error occurred: ${response.statusText}`);
  }

  if (onProgress) {
    let length = 0;
    // call onProgress in 2 ms intervals and updating length based on the result
    // from the response body
    const reader = response.clone().body?.getReader();
    if (reader) {
      const interval = setInterval(async () => {
        const { value, done } = await reader.read();
        if (value) {
          length += value.byteLength;
          onProgress(length);
        }
        if (done) {
          clearInterval(interval);
        }
      }, 2);
    }
  }

  return response.text();
}

export async function fetchBackendFormData(
  path: string,
  method: 'POST' | 'GET',
  body: FormData, // Add this line to accept a request body as Form Data
  onProgress?: (bytes: number) => void,
): Promise<string> {
  const url = new URL(path, environmentSubject.value.graphQLEndpoint).toString();
  const auth = currentAuth();

  const fetchOptions: RequestInit = {
    method,
    headers: {
      Accept: '*/*',
      authorization: auth ? `Bearer ${auth.idToken}` : '',
    },
  };

  // Add the body to the request if the method is POST and the body is not undefined
  if (method === 'POST' && body !== undefined) {
    fetchOptions.body = body;
  }

  const response = await fetch(url, fetchOptions);

  if (!response.ok) {
    // If the response is not 2xx, throw an error
    throw new Error(`An error occurred: ${response.statusText}`);
  }

  if (onProgress) {
    let length = 0;
    // call onProgress in 2 ms intervals and updating length based on the result
    // from the response body
    const reader = response.clone().body?.getReader();
    if (reader) {
      const interval = setInterval(async () => {
        const { value, done } = await reader.read();
        if (value) {
          length += value.byteLength;
          onProgress(length);
        }
        if (done) {
          clearInterval(interval);
        }
      }, 2);
    }
  }

  return response.text();
}
