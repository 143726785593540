import React from 'react';
import Paper from '@mui/material/Paper';
import {
  useStoredCpvSetsForPerformanceTestLazyQuery,
} from '../../../generated/graphql';
import { StoredCpvSetFetcherForm } from './StoredCpvSetFetcherForm';
import { StoredCpvSetPreview } from './StoredCpvSetPreview';
import { BreakdownPerformanceTestGraphQl } from './BreakdownPerformanceTestGraphQl';
import ErrorMessage from '../../common/ErrorMessage';
import { BreakdownPerformanceTestRest } from './BreakdownPerformanceTestRest';
import { BreakdownPerformanceTestPno } from './BreakdownPerformanceTestPno';
import { LabelBreakdownPerformanceTest } from './LabelBreakdownPerformanceTest';

export function BreakdownPerformanceTest() {
  const [call, { data, loading, error }] = useStoredCpvSetsForPerformanceTestLazyQuery();

  return (
    <div>
      <Paper sx={{ p: 2, mt: 2 }}>
        <StoredCpvSetFetcherForm
          onSubmit={(input) => call({
            variables: input,
          })}
          loading={loading}
        />
      </Paper>

      <ErrorMessage error={error}/>

      {data && (
        <StoredCpvSetPreview result={data.storedCpvSets}/>
      )}

      {data && (
        <BreakdownPerformanceTestGraphQl
          inputs={data.storedCpvSets.map((row) => ({
            features: row.cpAndCpvCodes,
            structureWeek: row.effectiveInWeek || 0,
            typeCode: row.modelYearDetails?.typeCode.toString() || '',
          }))}
        />
      )}

      {data && (
        <BreakdownPerformanceTestRest
          inputs={data.storedCpvSets.map((row) => ({
            key: row.name,
            plant: row.plant?.plantCode || 0,
            features: row.cpAndCpvCodes,
            structureWeek: row.effectiveInWeek || 0,
            typeCode: row.modelYearDetails?.typeCode.toString() || '',
          }))}
        />
      )}

      {data && (
        <BreakdownPerformanceTestPno
          structureWeek={data.storedCpvSets[0].effectiveInWeek || 0}
          plantCode={data.storedCpvSets[0].plant?.plantCode || 0}
          pnos={data.storedCpvSets.map((row) => ({
            name: row.name,
          }))}
        />
      )}

      {(data && data.storedCpvSets[0]) && (
        <LabelBreakdownPerformanceTest
          input={{
            key: data.storedCpvSets[0].name,
            plantCode: data.storedCpvSets[0].plant?.plantCode || 0,
            features: data.storedCpvSets[0].cpAndCpvCodes,
            structureWeek: data.storedCpvSets[0].effectiveInWeek || 0,
            typeCode: data.storedCpvSets[0].modelYearDetails?.typeCode.toString() || '',
          }}
        />
      )}
    </div>
  );
}
